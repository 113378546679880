<template>
    <div class="">

        <!-- Page Banner Start-->
        <PageBanner
            :pgLoading="pgLoading"
            :background="(item.background) ? item.background.url : ''"
            :prevSlug="'news'"
            :prevPage="($i18n.locale == 'ar') ? 'الأخبار' : 'News'"
            :currentPage="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
        </PageBanner>
        <!-- Page Banner End -->


        <!-- News Details Start -->
        <section id="news-section-1" class="news-section-details property-details padding_top">
            <div class="container">
                
                <div class="row heading_space">

                    <div class="col-md-8 col-x-12">
                        <div class="row">
                        
                            <div class="news-1-box clearfix">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <img 
                                        v-lazy="(item.image) ? item.image.url : ''" 
                                        :src="(item.image) ? item.image.url : ''" 
                                        :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''" 
                                        :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''" 
                                        style="width: 100%"
                                        class="img-responsive"/>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 top30">
                                    <div class="news-details bottom10">
                                        <span><i class="icon-icons228"></i> {{ item.created_at }}</span>
                                    </div>
                                    <h3 :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                                        v-html="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                                    </h3>
                                    <p><br/></p>
                                    <p :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''" 
                                        v-html="($i18n.locale == 'ar') ? item.body_ar : item.body_en">
                                    </p>
                                </div>
                            </div>
                        </div>
                        

                        <SocialShareCard :share_url="item.share_url"></SocialShareCard>
                        
                        
                        <div class="row">
                            <div class="col-md-12 bottom20">
                                <h2 class="text-uppercase"
                                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ $t('app.similar_news') }}
                                </h2>
                            </div>
                            
                            <div v-for="(row, index) in others"
                                :key="index"
                                class="col-md-6 col-sm-6 col-xs-12">

                                <NewsCard :item="row"></NewsCard>

                            </div>
                        </div>
                        
                    </div>
                
                    <aside class="col-md-4 col-xs-12">
                        <SearchCard></SearchCard>
                            
                        <CategoryCard></CategoryCard>

                        <FeaturedAside 
                            :title="'Featured News'"
                            :refs="'news'"
                            :paginate="6">
                        </FeaturedAside>
                    </aside>
                </div>
                
                      
            </div>
        </section>

    </div>
</template>


<script>
export default {
    name: 'Show',
    components: {
        PageBanner: () => import('@/components/PageBanner.vue'),
        NewsCard: () => import('@/components/NewsCard.vue'),
        SocialShareCard: () => import('@/components/SocialShareCard.vue'),
        SearchCard: () => import('@/components/SearchCard.vue'),
        CategoryCard: () => import('@/components/CategoryCard.vue'),
        FeaturedAside: () => import('@/components/FeaturedAside.vue'),
    },
    data(){
        return {
            pgLoading: false,
            item: '',
            others: [],
        }
    },
    metaInfo() {
        return {
            title: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_title : this.item.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_description : this.item.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_keywords : this.item.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_title : this.item.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {
    
        this.fetchRow();
        this.fetchOthers();
    },
    methods: {
          
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/news/'+this.$route.params.slug,
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.item = res.data.item;
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchOthers() {
            //this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/news',
                method: 'GET',
                data: {},
                params: {
                    
                    paginate: 2,
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.others = res.data.items;
            })
            .catch(() => {})
            .finally(() => {});
        },
      
    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
